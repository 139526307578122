<template>
  <div style="background:rgba(249,249,249,1);height:100vh">
    <div class="topNavBox">
      <div class="topbox clearfix">
        <a href="javaScript:" class="fl back" @click="seeBack">
          <img src="~img/back@2x.png" alt="" />
        </a>
        <p class="fl">我的賬戶</p>
      </div>
    </div>
    <div class="line"></div>
    <ul class="centenList">
      <li class="clearfix">
        <a class="clearfix" href="javaScript:" @click.stop="uploadHeadImg">
          <p class="fl">頭像</p>
          <img
            class="fr headimg"
            v-if="userInfo.iconUrlSmall"
            :src="userInfo.iconUrlSmall"
            alt=""
          />
          <input
            type="file"
            accept="image/*"
            @change="handleFile"
            class="hiddenInput"
          />
        </a>
      </li>
      <li class="clearfix">
        <router-link class="clearfix" to="/nickName">
          <p class="fl">暱稱</p>
          <img class="fr" src="~img/home/Path7@2x.png" alt="" />
          <p class="fr gender ellipsis">
            {{ userInfo.name }}
          </p>
        </router-link>
      </li>
      <li class="clearfix">
        <a class="clearfix" href="javaScript:" @click="getChoose">
          <p class="fl">性別</p>
          <img class="fr" src="~img/home/Path7@2x.png" alt="" />
          <p class="fr gender">
            {{
              userInfo.gender === 0
                ? "未設置"
                : userInfo.gender === 1
                ? "男"
                : "女"
            }}
            <span class="circle" v-if="userInfo.gender === 0"></span>
          </p>
        </a>
      </li>
    </ul>
    <mt-actionsheet :actions="actions" v-model="sheetVisible"> </mt-actionsheet>

    <button class="loginout" @click="logout">退出登錄</button>
  </div>
</template>

<script>
import { getuserInfo, uploadimg, changeGender } from "apiurl/user";
import Clipboard from "clipboard";
import $ from "jquery";
export default {
  data() {
    return {
      userInfo: {},
      actions: [
        { name: `男`, method: this.chooseNan },
        { name: `女`, method: this.chooseNv }
      ],
      sheetVisible: false
    };
  },
  created() {
    this.$nextTick(() => {
      // 獲取用戶信息
      this.getUserInfo();
    });
  },
  methods: {
    async getUserInfo() {
      let res = await getuserInfo();
      if (res.success) {
        this.userInfo = res.data;
        if (this.userInfo.gender == 1) {
          $(".mint-actionsheet-listitem")
            .first()
            .css({ color: "#649de0" });
          $(".mint-actionsheet-listitem")
            .eq(1)
            .css({ color: "#333" });
        } else if (this.userInfo.gender == 2) {
          $(".mint-actionsheet-listitem")
            .first()
            .css({ color: "#333" });
          $(".mint-actionsheet-listitem")
            .eq(1)
            .css({ color: "#649de0" });
        } else {
          $(".mint-actionsheet-listitem")
            .first()
            .css({ color: "#333" });
          $(".mint-actionsheet-listitem")
            .eq(1)
            .css({ color: "#333" });
        }
      }
    },
    seeBack() {
      this.$router.go(-1);
    },
    getChoose() {
      // 打開上拉彈框
      this.sheetVisible = true;
    },
    chooseNan() {
      changeGender({ gender: 1 }).then(res => {
        if (res.success) {
          this.Toast({
            message: "設置成功",
            duration: 2000,
            position: "top"
          });
          this.getUserInfo();
        }
      });
    },
    /* 退出 */
    logout() {
      this.$store
        .dispatch("LogOut")
        .then(res => {
          if (res.success) {
            this.$router.push({
              path: "/phoneLogin",
              query: { redirect: "/" }
            });
          }
        })
        .catch(() => {});
    },
    chooseNv() {
      changeGender({ gender: 2 }).then(res => {
        if (res.success) {
          this.Toast({
            message: "設置成功",
            duration: 2000,
            position: "top"
          });
          this.getUserInfo();
        }
      });
    },
    // 打開圖片上傳
    uploadHeadImg() {
      this.$el.querySelector(".hiddenInput").click();
    },
    handleFile(e) {
      let $target = e.target || e.srcElement;
      let file = $target.files[0];
      let formdata = new FormData();
      formdata.append("iconFile", file);
      uploadimg(formdata).then(res => {
        if (res.success) {
          this.userInfo.iconUrlSmall = res.data.iconUrl;
          this.Toast({
            message: "修改成功~",
            duration: 2000,
            position: "top"
          });
        }
      });
    },
    // 複製Id
    copyId() {
      let clipboard = new Clipboard(".userId");
      clipboard.on("success", () => {
        this.Toast({
          message: "複製成功",
          duration: 2000,
          position: "top"
        });
        // 釋放內存
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        this.Toast({
          message: "該瀏覽器不支持自動複製",
          duration: 2000,
          position: "top"
        });
        // 釋放內存
        clipboard.destroy();
      });
    },
    // 去綁定手機號
    isBindMobile() {
      if (!this.userInfo.mobile) {
        this.$router.push("/bindPhone");
      } else {
        this.Toast({
          message: "已綁定",
          duration: 2000,
          position: "top"
        });
      }
    },
    isPassFun() {
      if (this.userInfo.mobile) {
        if (this.userInfo.hasPassword) {
          this.$router.push("/changePassword");
        } else {
          this.$router.push("/setPassword");
        }
      } else {
        this.Toast({
          message: "請先綁定手機號",
          duration: 2000,
          position: "top"
        });
      }
    }
  }
};
</script>
<style>
.mint-actionsheet-listitem {
  font-size: 30px;
  padding: 25px 0;
  height: auto;
  line-height: inherit;
}
.mint-actionsheet-button {
  font-size: 30px;
  padding: 25px 0;
  height: auto;
  line-height: inherit;
}
</style>

<style lang="stylus" scoped>
.centenList
    background #fff
    li
        padding 0 25px 0 23px
        border-bottom 2px solid #eee
        .item
            width 36px
            height 36px
        a
            height 100%
            display block
            line-height 98px
            img
                width 14px
                margin-top 39px
                &.headimg
                    width 59px
                    height 59px
                    border-radius 50%
                    margin-top 17px
            p
                display block
                line-height 98px
            .gender
                margin-right 29px
                position relative
                color #999999
                max-width 45%
                text-align right
                .circle
                    width 12px
                    height 12px
                    position absolute
                    right -15px
                    top 20px
                    background rgba(255, 0, 0, 1)
                    border-radius 50%
        button
            width 87px
            height 45px
            background rgba(92, 162, 245, 1)
            border-radius 23px
            color #fff
        p
            color #323232
            line-height 36px
            font-size 30px
            span
                color #999999
                display inline-block
                margin-left 5px
                font-size 24px
.hiddenInput
    display none
.prompt
    height 80px
    line-height 80px
    padding-left 24px
    font-size 24px
    color rgba(153, 153, 153, 1)
.loginout
    width:332px;
    height:56px;
    background:linear-gradient(135deg,rgba(122,209,255,1) 0%,rgba(29,133,241,1) 100%);
    box-shadow:0px 1px 8px 0px rgba(118,206,254,1);
    border-radius:28px;
    color #fff
    font-size:28px;
    display block
    margin 80px auto 0
</style>
